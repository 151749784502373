<template>
	<div>
		<div class="produto-adicionar">
			<div class="conteudo-interno">
				<div class="nova-comunicacao-container">
					<h1>Adicionar Treinamento</h1>
					<FormularioBannerTreinamento :editar="false" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const FormularioBannerTreinamento = () =>
	import("@/components/elementos/paginas/FormularioBannerTreinamento.vue");
export default {
	name: "CadastroBannerTreinamentosAdicionar",
	components: {
		FormularioBannerTreinamento
	}
};
</script>

<style lang="scss">
.produto-adicionar {
	position: relative;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
}
</style>
